export const dateISOFormat = (date: string | null) => {
  if (date) {
    try {
      return new Date(date).toISOString().slice(0, 19).replace('T', ' ');
    } catch (dateExcep) {
      return null;
    }
  } else {
    return null;
  }
};

export const volumeCalc = (
  width: number | undefined,
  long: number | undefined,
  height: number | undefined
) => {
  if (!width || !long || !height) {
    return 0;
  }
  return width * long * height;
};

export const surfaceCalc = (
  width: number | undefined,
  long: number | undefined
) => {
  if (!width || !long) {
    return 0;
  }
  return width * long;
};

export const arsFormatter = (amount: number | bigint) =>
  Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(
    amount
  );

export const floatFormatter = (amount: number | bigint) => amount.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export const formatSettlementTypeDescription = (str: string): string => {
  return str.replace(/_/g, ' ');
}

export const serialToDateTime = (serialString: string): string => {
  const serial = parseInt(serialString, 10);

  const baseDate = new Date(Date.UTC(1900, 0, 1));
  const adjustedDate = new Date(baseDate.getTime() + (serial - 2) * 24 * 60 * 60 * 1000);

  adjustedDate.setUTCHours(3, 0, 0, 0);

  const year = adjustedDate.getUTCFullYear();
  const month = String(adjustedDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(adjustedDate.getUTCDate()).padStart(2, '0');
  const hours = String(adjustedDate.getUTCHours()).padStart(2, '0');
  const minutes = String(adjustedDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(adjustedDate.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
