import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import {
  EXCEL_HEADERS,
  EXCEL_SHEETS,
  MOVEMENT_TYPES,
  STATUS_CODES,
} from "constants/index";
import { ARTICLE_LOCATIONS_URL, MOVEMENTS_URL } from "constants/urls";
import { serialToDateTime } from "helpers";
import { validateSchema } from "helpers/schemaValidator";
import useCrud from "hooks/useCrud";
import { useToast } from "hooks/useToast";
import { useRef, useState } from "react";
import { MdCancel, MdCheck, MdCloudUpload, MdRestartAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  AdditionalItem,
  Article,
  ArticleLocation,
  Client,
  Store,
  User,
  Location,
  Movement,
  MovementArticle,
  MovementAdditionalItem,
  ExcelCellProps,
} from "types";
import { read, utils } from "xlsx";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type FileStructure = Movement[];
export default function FileImport({
  movementType,
  locations,
  clients,
  stores,
  users,
  additional_items,
  articles,
}: {
  movementType: string;
  locations: Location[];
  clients: Client[];
  stores: Store[];
  users: User[];
  additional_items: AdditionalItem[];
  articles: Article[];
}) {
  const [uploadedFile, setUploadedFile] = useState<FileStructure | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [listOfErrors, setListOfErrors] = useState<string[]>([]);
  const [resetInput, setResetInput] = useState(Math.random().toString(36));
  const fileUploadRef = useRef(null);
  const { createData: createMovements } = useCrud<FileStructure>(MOVEMENTS_URL);
  const navigate = useNavigate();
  const { showSnackBar } = useToast();
  const { data: articleLocations, fetchData: fetchArticleLocations } =
    useCrud<ArticleLocation>(ARTICLE_LOCATIONS_URL);
  const handleModal = () => {
    setError(false);
    setModalOpen(!modalOpen);
  };
  function createFileStructure({
    movimientos,
    articulos,
    items_adicionales,
  }: {
    movimientos: Record<string, unknown>[];
    articulos: Record<string, unknown>[];
    items_adicionales: Record<string, unknown>[];
  }): FileStructure {
    const fileStructure: FileStructure = [];
    if (movementType === MOVEMENT_TYPES.INCOME.label) {
      movimientos.forEach((movimiento) => {

        const client = clients.find(
          (client) => String(client.code) === String(movimiento[EXCEL_HEADERS.CABECERA.CLIENTE])
        );

        const store = stores.find(
          (store) =>
            store.code === movimiento[EXCEL_HEADERS.CABECERA.CODIGO_ALMACEN]
        );

        const userCreated = users.find(
          (user) =>
            `${user.first_name} ${user.last_name}` ===
            movimiento[EXCEL_HEADERS.CABECERA.CREATED_BY]
        );

        const userUpdated = users.find(
          (user) =>
            `${user.first_name} ${user.last_name}` ===
            movimiento[EXCEL_HEADERS.CABECERA.UPDATED_BY]
        );

        const movimientoStructure: Movement = {
          containers: [],
          movement_type_id: MOVEMENT_TYPES.INCOME.id,
          numero_remito: String(movimiento[EXCEL_HEADERS.CABECERA.REMITO]),
          date: serialToDateTime(
            movimiento[EXCEL_HEADERS.CABECERA.FECHA] as string
          ) as string,
          client_id: client?.id as number,
          associated_client: movimiento[
            EXCEL_HEADERS.CABECERA.CLIENTE_ASOCIADO
          ] as string,
          client_document: client?.cuit as string,
          pallets: movimiento[EXCEL_HEADERS.CABECERA.PALLETS] as number,
          observation: movimiento[
            EXCEL_HEADERS.CABECERA.OBSERVACIONES
          ] as string,
          created_by: userCreated?.id as number,
          updated_by: userUpdated?.id as number,
          is_billable: false,
          store_id: store?.id as number,
          weight: movimiento[EXCEL_HEADERS.CABECERA.PESO] as number,
          articles: [],
          additional_items: [],
        };

        fileStructure.push(movimientoStructure);
      });

      articulos.forEach((articulo, index) => {
        const article = articles.find(
          (article) =>
            article.code === articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]
        );

        if (!article) {
          console.error(
            `Artículo con código ${articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]} no encontrado.`
          );
          setError(true);
          setListOfErrors((prevState) => [
            ...prevState,
            `Artículo no encontrado: ${articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]}`,
          ]);
          return;
        }

        const location = locations.find(
          (location) =>
            location.code === articulo[EXCEL_HEADERS.ARTICULOS.UBICACION]
        );

        fetchArticleLocations(
          `?articleId=${article?.id}&locationId=${location?.id}`
        );
        const articleLocation = articleLocations[0];

        const articuloStructure: MovementArticle = {
          id: index,
          article_locations: (articleLocation as ArticleLocation) || {
            id: 0,
            article_id: article?.id as number,
            location_id: location?.id as number,
            article: article as Article,
            location: location as Location,
            stock: articulo[EXCEL_HEADERS.ARTICULOS.CANTIDAD] as number,
            amount_per_container: 1,
          },
          article_locations_id: 0,
          article_id: article?.id as number,
          client_id: article?.client.id as number,
          location_id: location?.id as number,
          container: article?.container.description as string,
          description: article?.description as string,
          weight: article?.weight as number,
          volume_weight: article?.volume_weight as number,
          unit: article?.unit.description as string,
          amount: articulo[EXCEL_HEADERS.ARTICULOS.CANTIDAD] as number,
          amount_per_container: articulo[
            EXCEL_HEADERS.ARTICULOS.CANTIDAD_POR_CONTENEDOR
          ] as number,
          subtotal: articulo[EXCEL_HEADERS.ARTICULOS.SUBTOTAL] as number,
          subtotal_kg: article.amount * article.weight,
        };

        fileStructure.forEach((movimiento) => {
          if (
            String(movimiento.numero_remito) === String(articulo[EXCEL_HEADERS.ARTICULOS.REMITO])
          ) {
            movimiento.articles.push(articuloStructure);
          }
        });
      });

      items_adicionales.forEach((item, index) => {
        const additionalItem = additional_items.find(
          (additional_item) =>
            additional_item.description ===
            item[EXCEL_HEADERS.ADICIONALES.DESCRIPCION]
        );

        const additionalItemStructure: MovementAdditionalItem = {
          id: index,
          additional_item_id: additionalItem?.id as number,
          description: additionalItem?.description as string,
          additional_item: additionalItem as AdditionalItem,
          amount: item[EXCEL_HEADERS.ADICIONALES.CANTIDAD] as number,
        };

        fileStructure.forEach((movimiento) => {
          if (
            String(movimiento.numero_remito) === String(item[EXCEL_HEADERS.ADICIONALES.REMITO])
          ) {
            movimiento.additional_items.push(additionalItemStructure);
          }
        });
      });
    } else {
      movimientos.forEach((movimiento) => {
        const client = clients.find(
          (client) => String(client.code) === String(movimiento[EXCEL_HEADERS.CABECERA.CLIENTE])
        );
        const store = stores.find((store) => store.code === movimiento.Almacen);
        const userCreated = users.find(
          (user) =>
            `${user.first_name} ${user.last_name}` ===
            movimiento[EXCEL_HEADERS.CABECERA.CREATED_BY]
        );
        const userUpdated = users.find(
          (user) =>
            `${user.first_name} ${user.last_name}` ===
            movimiento[EXCEL_HEADERS.CABECERA.UPDATED_BY]
        );

        const movimientoStructure: Movement = {
          containers: [],
          movement_type_id:
            movementType === MOVEMENT_TYPES.DISCHARGE.label
              ? MOVEMENT_TYPES.DISCHARGE.id
              : MOVEMENT_TYPES.PREPARATION.id,
          numero_remito: String(movimiento[EXCEL_HEADERS.CABECERA.REMITO]),
          date: serialToDateTime(
            movimiento[EXCEL_HEADERS.CABECERA.FECHA] as string
          ) as string,
          client_id: client?.id as number,
          associated_client: movimiento[
            EXCEL_HEADERS.CABECERA.CLIENTE_ASOCIADO
          ] as string,
          client_document: client?.cuit as string,
          pallets: movimiento[EXCEL_HEADERS.CABECERA.PALLETS] as number,
          observation: movimiento[
            EXCEL_HEADERS.CABECERA.OBSERVACIONES
          ] as string,
          created_by: userCreated?.id as number,
          updated_by: userUpdated?.id as number,
          is_billable: false,
          store_id: store?.id as number,
          weight: movimiento[EXCEL_HEADERS.CABECERA.PESO] as number,
          articles: [],
          additional_items: [],
        };
        fileStructure.push(movimientoStructure);
      });

      articulos.forEach((articulo, index) => {
        const article = articles.find(
          (article) =>
            article.code === articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]
        );

        if (!article) {
          console.error(
            `Artículo con código ${articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]} no encontrado.`
          );
          setError(true);
          setListOfErrors((prevState) => [
            ...prevState,
            `Artículo no encontrado: ${articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]}`,
          ]);
          return;
        }
        
        const location = locations.find(
          (location) =>
            location.code === articulo[EXCEL_HEADERS.ARTICULOS.UBICACION]
        );

        fetchArticleLocations(
          `?articleId=${article?.id}&locationId=${location?.id}`
        );
        const articleLocation = articleLocations[0];

        if (!articleLocation) {
          setError(true);
          setListOfErrors([
            ...listOfErrors,
            `El articulo ${
              articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]
            } no se encuentra en la ubicacion ${
              articulo[EXCEL_HEADERS.ARTICULOS.UBICACION]
            }`,
          ]);
        } else {
          const articuloStructure: MovementArticle = {
            id: index,
            article_locations: articleLocation,
            article_locations_id: articleLocation?.id,
            article_id: article?.id as number,
            client_id: article?.client.id as number,
            location_id: location?.id as number,
            container: article?.container.description as string,
            description: article?.description as string,
            weight: article?.weight as number,
            volume_weight: article?.volume_weight as number,
            unit: article?.unit.description as string,
            amount: articulo[EXCEL_HEADERS.ARTICULOS.CANTIDAD] as number,
            amount_per_container: articulo[
              EXCEL_HEADERS.ARTICULOS.CANTIDAD_POR_CONTENEDOR
            ] as number,
            subtotal: articulo[EXCEL_HEADERS.ARTICULOS.SUBTOTAL] as number,
            subtotal_kg: article.amount * article.weight,
          };

          fileStructure.forEach((movimiento) => {
            if (
              String(movimiento.numero_remito) === String(articulo[EXCEL_HEADERS.ARTICULOS.REMITO])
            ) {
              movimiento.articles.push(articuloStructure);
            }
          });
        }
      });

      items_adicionales.forEach((item, index) => {
        const additionalItem = additional_items.find(
          (additional_item) =>
            additional_item.description ===
            item[EXCEL_HEADERS.ADICIONALES.DESCRIPCION]
        );
        const additionalItemStructure: MovementAdditionalItem = {
          id: index,
          additional_item_id: additionalItem?.id as number,
          description: additionalItem?.description as string,
          additional_item: additionalItem as AdditionalItem,
          amount: item[EXCEL_HEADERS.ADICIONALES.CANTIDAD] as number,
        };

        fileStructure.forEach((movimiento) => {
          if (
            String(movimiento.numero_remito) === String(item[EXCEL_HEADERS.ADICIONALES.REMITO])
          ) {
            movimiento.additional_items.push(additionalItemStructure);
          }
        });
      });
    }
    return fileStructure;
  }

  const handleSubmit = async () => {
    const movementResponse = await createMovements(
      uploadedFile as FileStructure
    );
    if (movementResponse.status === STATUS_CODES.OK) {
      showSnackBar("Se han creado los movimientos correctamente", "success");
      navigate("/operations", {
        state: {
          clientId: movementResponse.data.client_id,
        },
      });
    } else {
      showSnackBar(
        movementResponse.status === STATUS_CODES.BAD_REQUEST
          ? "Existen números de remito que ya se encuentra registrados, por favor modifiquelos e intente nuevamente."
          : "Los movimientos no han podido crearse, por favor intente nuevamente",
        "error"
      );
    }
  };

  const inputMimeType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

  const readFile = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      // Documentación XLSX
      // Se instancia el API nativo de FileReader, el cual maneja el evento y los datos del File en la carga
      reader.onload = (e) => {
        // en onload() se procesa los datos del archivo cargado con la librería xlsx
        const workbook = read(e.target?.result, { type: "array" });

        //validar que el archivo tenga las 3 hojas necesarias (Cabecera, Articulos, Items Adicionales)
        //validar que las hojas tengan los nombres correctos
        if (
          !workbook.Sheets[workbook.SheetNames[0]] ||
          workbook.SheetNames[0] !== EXCEL_SHEETS.CABECERA ||
          !workbook.Sheets[workbook.SheetNames[1]] ||
          workbook.SheetNames[1] !== EXCEL_SHEETS.ARTICULOS ||
          !workbook.Sheets[workbook.SheetNames[2]] ||
          workbook.SheetNames[2] !== EXCEL_SHEETS.ADICIONALES
        ) {
          setError(true);
        }

        //convertir a cabecera el contenido de las hojas
        const cabecera = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        ) as Record<string, unknown>[];

        const articulos = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[1]]
        ) as Record<string, unknown>[];

        const itemsAdicionales = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[2]]
        ) as Record<string, unknown>[];

        const uniqueMovements = new Set(
          cabecera.map(
            (movimiento) => movimiento[EXCEL_HEADERS.CABECERA.REMITO]
          )
        );

        const uniqueArticles = new Set(
          articulos.map((articulo) => {
            return {
              movement: articulo[EXCEL_HEADERS.ARTICULOS.REMITO],
              article: articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO],
            };
          })
        );

        const uniqueAdditionalItems = new Set(
          itemsAdicionales.map((item) => {
            return {
              movement: item[EXCEL_HEADERS.ADICIONALES.REMITO],
              item: item[EXCEL_HEADERS.ADICIONALES.DESCRIPCION],
            };
          })
        );

        // Validar que haya movimientos y al menos un articulo o item adicional
        // Validar que no haya movimientos, articulos o items adicionales repetidos

        if (
          !cabecera.length ||
          (!articulos.length && !itemsAdicionales.length) ||
          uniqueMovements.size !== cabecera.length ||
          uniqueArticles.size !== articulos.length ||
          uniqueAdditionalItems.size !== itemsAdicionales.length
        ) {
          setError(true);
        }

        //Validar que los articulos del movimiento pertenezcan al cliente que ponen en la cabecera del movimiento.

        cabecera.forEach((movimiento) => {
          const articulosDelMovimiento = articulos.filter(
            (articulo) =>
              articulo[EXCEL_HEADERS.ARTICULOS.REMITO] ===
              movimiento[EXCEL_HEADERS.CABECERA.REMITO]
          );

          const itemsAdicionalesDelMovimiento = itemsAdicionales.filter(
            (item) =>
              item[EXCEL_HEADERS.ADICIONALES.REMITO] ===
              movimiento[EXCEL_HEADERS.CABECERA.REMITO]
          );

          articulosDelMovimiento.forEach((articulo) => {
            const articuloEncontrado = articles.find(
              (article) =>
                article.code ===
                articulo[EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]
            );
            if (
              articuloEncontrado?.client.code !==
              movimiento[EXCEL_HEADERS.CABECERA.CLIENTE]
            ) {
              setError(true);
            }
          });

          itemsAdicionalesDelMovimiento.forEach((item) => {
            const itemEncontrado = additional_items.find(
              (additional_item) =>
                additional_item.description ===
                item[EXCEL_HEADERS.ADICIONALES.DESCRIPCION]
            );
            if (
              itemEncontrado?.client_id !== null &&
              itemEncontrado?.client?.code !==
                movimiento[EXCEL_HEADERS.CABECERA.CLIENTE]
            ) {
              setError(true);
            }
          });
        });

        // Validar que la cabecera tenga los campos necesarios y que los articulos y items adicionales tengan los campos necesarios y las asociaciones correctas

        const cabeceraSchema: Record<string, ExcelCellProps> = {
          [EXCEL_HEADERS.CABECERA.REMITO]: { type: "string", required: true },
          [EXCEL_HEADERS.CABECERA.FECHA]: { type: "string", required: true },
          [EXCEL_HEADERS.CABECERA.CLIENTE]: {
            type: "string",
            required: true,
            associations: clients.map((client) => client.code),
          },
          [EXCEL_HEADERS.CABECERA.CLIENTE_ASOCIADO]: {
            type: "string",
            required: true,
          }, //TODO: falta asociacion
          [EXCEL_HEADERS.CABECERA.CREATED_BY]: {
            type: "string",
            required: true,
            associations: users.map(
              (user) => `${user.first_name} ${user.last_name}`
            ),
          },
          [EXCEL_HEADERS.CABECERA.UPDATED_BY]: {
            type: "string",
            required: true,
            associations: users.map(
              (user) => `${user.first_name} ${user.last_name}`
            ),
          },
          [EXCEL_HEADERS.CABECERA.CODIGO_ALMACEN]: {
            type: "string",
            required: true,
            associations: stores.map((store) => store.code),
          },
          [EXCEL_HEADERS.CABECERA.OBSERVACIONES]: {
            type: "string",
            required: false,
          },
          [EXCEL_HEADERS.CABECERA.PALLETS]: { type: "number", required: true },
          [EXCEL_HEADERS.CABECERA.PESO]: { type: "number", required: true },
        };

        const articulosSchema: Record<string, ExcelCellProps> = {
          [EXCEL_HEADERS.ARTICULOS.REMITO]: { type: "string", required: true },
          [EXCEL_HEADERS.ARTICULOS.CODIGO_ARTICULO]: {
            type: "string",
            required: true,
            associations: articles.map((article) => article.code),
          },
          [EXCEL_HEADERS.ARTICULOS.CANTIDAD]: {
            type: "number",
            required: true,
          },
          [EXCEL_HEADERS.ARTICULOS.LOTE]: { type: "string", required: false },
          [EXCEL_HEADERS.ARTICULOS.FECHA_VENCIMIENTO]: {
            type: "string",
            required: false,
          },
          [EXCEL_HEADERS.ARTICULOS.ORDEN_DE_COMPRA]: {
            type: "string",
            required: false,
          },
          [EXCEL_HEADERS.ARTICULOS.CANTIDAD_POR_CONTENEDOR]: {
            type: "number",
            required: true,
          },
          [EXCEL_HEADERS.ARTICULOS.UBICACION]: {
            type: "string",
            required: true,
            associations: locations.map((location) => location.code),
          },
          [EXCEL_HEADERS.ARTICULOS.SUBTOTAL]: {
            type: "number",
            required: true,
          },
        };

        const itemsAdicionalesSchema: Record<string, ExcelCellProps> = {
          [EXCEL_HEADERS.ADICIONALES.REMITO]: {
            type: "string",
            required: true,
          },
          [EXCEL_HEADERS.ADICIONALES.DESCRIPCION]: {
            type: "string",
            required: true,
          }, //TODO: ver si habria que asociar contra additional_items
          [EXCEL_HEADERS.ADICIONALES.CANTIDAD]: {
            type: "number",
            required: true,
          },
        };

        const isCabeceraValid = validateSchema(cabecera, cabeceraSchema);
        const areArticulosValid = validateSchema(articulos, articulosSchema);
        const areItemsAdicionalesValid = validateSchema(
          itemsAdicionales,
          itemsAdicionalesSchema
        );

        // Si el archivo tiene algun error lo muestro al usuario y no proceso los movimientos
        if (
          !isCabeceraValid.isValid ||
          !areArticulosValid.isValid ||
          !areItemsAdicionalesValid.isValid
        ) {
          for (const error of isCabeceraValid.errors) {
            const errorString = `${EXCEL_SHEETS.CABECERA}: ${error.message}`;
            setListOfErrors((prevState) => [...prevState, errorString]);
          }
          for (const error of areArticulosValid.errors) {
            const errorString = `${EXCEL_SHEETS.ARTICULOS}: ${error.message}`;
            setListOfErrors((prevState) => [...prevState, errorString]);
          }
          for (const error of areItemsAdicionalesValid.errors) {
            const errorString = `${EXCEL_SHEETS.ADICIONALES}: ${error.message}`;
            setListOfErrors((prevState) => [...prevState, errorString]);
          }
          setError(true);
        }

        // Armo el body desnormalizado
        setUploadedFile(
          createFileStructure({
            movimientos: cabecera,
            articulos,
            items_adicionales: itemsAdicionales,
          })
        );
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      if (reader.readyState === 1) {
        handleModal();
        if (reader.error) {
          setError(true);
        }
      }
    } else {
      setError(true);
    }
    return setLoading(false);
  };

  return (
    <>
      <Button
        component="label"
        variant="contained"
        startIcon={<MdCloudUpload />}
      >
        {loading ? <CircularProgress /> : "Cargar Archivo"}
        <VisuallyHiddenInput
          ref={fileUploadRef}
          key={resetInput}
          onChange={readFile}
          accept={inputMimeType}
          type="file"
        />
      </Button>
      <Modal
        open={modalOpen}
        onClose={() => {
          handleModal();
          setUploadedFile(null);
          setResetInput(Math.random().toString(36));
        }}
      >
        <Paper
          sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            maxHeight: "90vh",
            width: "auto",
            transform: "translate(-50%, -50%)",
            padding: "1rem",
            overflowY: "auto"
          }}
        >
          <Grid container>
            {loading ? (
              <Grid item xs={12}>
                <Typography variant="body1">Cargando...</Typography>
              </Grid>
            ) : (
              !error &&
              uploadedFile && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Datos Importados Correctamente
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12} py={4}>
                    <Typography variant="body1">
                      Se importaron{" "}
                      <Typography color={"green"}>
                        ({uploadedFile.length})
                      </Typography>{" "}
                      movimientos
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    gap={2}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleModal();
                        setUploadedFile(null);
                        setResetInput(Math.random().toString(36));
                      }}
                      startIcon={<MdCancel />}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      startIcon={<MdCheck />}
                    >
                      Cargar Datos
                    </Button>
                  </Grid>
                </>
              )
            )}
            {error && (
              <Grid container justifyContent="center" rowGap={2}>
                <Grid item xs={12}>
                  <Typography padding={2} variant="h5">
                    Ocurrio un Error
                  </Typography>
                </Grid>
                <Grid item xs={12} rowGap={5}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Se encontraron{" "}
                      <Typography color={"red"}>
                        ({listOfErrors.length})
                      </Typography>{" "}
                      errores
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        {listOfErrors.map((error, index) => (
                          <Grid item xs={12} key={index}>
                            <Alert severity="error">{error}</Alert>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      setUploadedFile(null);
                      setResetInput(Math.random().toString(36));
                      setError(false);
                      setModalOpen(false);
                    }}
                    startIcon={<MdRestartAlt />}
                    variant="contained"
                  >
                    Reintentar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}
